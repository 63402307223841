import React from 'react';
import { Link } from 'react-router-dom';
import img404 from "../../assets/images/error/404.webp";

function NotFound() {
  return (
    <div className="flex items-center justify-center h-screen bg-[#06071B] text-white p-4">
      {/* Contenedor principal con diseño rectangular y compacto */}
      <div className="flex flex-col md:flex-row items-center border-dashed border-2 border-red-400 rounded-lg bg-[#0A0C2A] max-w-sm w-full mx-auto p-5">
        {/* Espacio para la imagen */}
        <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-5">
          <img src={img404} alt="Error 404" className="w-24 h-auto mx-auto md:w-28" />
        </div>

        {/* Contenido de texto */}
        <div className="text-center md:text-left">
          <h1 className="text-2xl md:text-3xl font-bold text-red-500 mb-2">404</h1>
          <h2 className="text-lg font-semibold mb-2">Página no encontrada</h2>
          <p className="text-sm text-gray-300 mb-4">
            Lo sentimos, la página que estás buscando no existe o ha sido movida.
          </p>
          <Link
            to="/"
            className="inline-block px-4 py-2 bg-red-500 text-white text-sm rounded-lg hover:bg-red-600 transition"
          >
            Volver a Inicio
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
