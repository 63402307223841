import React from 'react';

const Biography = () => {
  return (
    <section className="bg-[#0A0C2A] shadow-lg rounded-lg p-8 text-white">
      <h2 className="text-2xl font-bold mb-4">Biografía</h2>
      <p className="text-lg text-gray-300 leading-relaxed">
        Soy un estudiante de Ingeniería de Sistemas Computacionales en mi noveno ciclo, 
        con una sólida formación en desarrollo web frontend y backend, diseño y arquitectura
        de software, bases de datos relacionales y no relacionales, computación en la nube 
        y ciencia de datos.
        Mi aprendizaje no se limita al ámbito académico; he complementado mi formación con 
        iniciativas reconocidas como la beca "Generación Digital", centrada en Programación 
        Web Full Stack, y el programa "Data Analytics" de IBM, que me capacitó en herramientas
        y metodologías clave para análisis de datos.
      </p>
      <p className="text-lg text-gray-300 leading-relaxed mt-4">
        Durante mi reciente pasantía en Grupo Frava, lideré equipos y contribuí al diseño y 
        desarrollo de un sitio web corporativo alineado a los objetivos comerciales de la empresa.
        También brindé soporte técnico, optimicé procesos tecnológicos mediante la implementación
        de sistemas y elaboré documentación técnica para mejorar la adopción organizacional. 
        Estas experiencias han consolidado mi capacidad de gestión, resolución de problemas y
        trabajo en equipo.
      </p>
      <p className="text-lg text-gray-300 leading-relaxed mt-4">
        Me apasiona aprender de forma autónoma, enfrentar desafíos tecnológicos y desarrollar 
        proyectos que generen impacto positivo en las personas y empresas. Actualmente,
        me especializo en tecnologías modernas como React, Spring Boot y análisis de datos 
        con herramientas como Power BI y Spark, apuntando siempre a un crecimiento profesional 
        continuo.
      </p>
    </section>
  );
};

export default Biography;
