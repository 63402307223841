import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import image from '../../assets/images/funny/imagen_2024-12-19_103308564.webp';
function ContactForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Evitar recarga de página
    setIsModalOpen(true); // Abrir modal
  };

  const handleClose = () => {
    setIsModalOpen(false); // Cerrar modal
  };

  return (
    <>
      <form
        className="bg-[#0A0C2A] p-8 rounded-lg shadow-lg space-y-6"
        onSubmit={handleSubmit}
      >
        <TextField
          fullWidth
          label="Nombre"
          variant="outlined"
          slotProps={{
            inputLabel: {
              style: { color: 'white' },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: '#3B82F6' },
              '&.Mui-focused fieldset': { borderColor: '#3B82F6' },
            },
            input: { color: 'white' },
          }}
        />
        <TextField
          fullWidth
          label="Correo"
          variant="outlined"
          slotProps={{
            inputLabel: {
              style: { color: 'white' },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: '#3B82F6' },
              '&.Mui-focused fieldset': { borderColor: '#3B82F6' },
            },
            input: { color: 'white' },
          }}
        />
        <TextField
          fullWidth
          label="Mensaje"
          variant="outlined"
          multiline
          rows={4}
          slotProps={{
            inputLabel: {
              style: { color: 'white' },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: '#3B82F6' },
              '&.Mui-focused fieldset': { borderColor: '#3B82F6' },
            },
            input: { color: 'white' },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#3B82F6',
            '&:hover': { backgroundColor: '#2563EB' },
          }}
        >
          Enviar
        </Button>
      </form>

      {/* Mini Modal */}
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Funcionalidad No Implementada</DialogTitle>
        <DialogContent>
          {/* Contenedor en columnas */}
          <div className="flex flex-col md:flex-row items-center gap-4">
            {/* Imagen */}
            <div className="flex-shrink-0">
              <img src={image} alt="No Funcional" className="w-32 h-32 object-contain mx-auto md:mx-0" />
            </div>
            {/* Texto */}
            <div className="text-center md:text-left">
              <p className="text-gray-600">
                Gracias por tu interés. Actualmente, este formulario no tiene funcionalidad activa.
                Por favor, contáctame a través de mis redes sociales o correo electrónico.
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContactForm;
