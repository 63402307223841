// About.jsx
import React from 'react';
import Biography from './Biography';
import Skills from './Skills';
import Timeline from './Timeline';
import Certifications from './Certifications';

const About = () => {
  return (
    <div className="min-h-screen bg-[#06071B] text-white py-16 px-8 mt-8">
      <div className="max-w-7xl mx-auto space-y-12">
        <header className="text-center">
          <h1 className="text-4xl font-bold mb-4">Sobre mí</h1>
          <p className="text-lg leading-relaxed">
            Aquí encontrarás información sobre mi trayectoria, habilidades y certificaciones.
          </p>
        </header>

        {/* Sección con dos cuadros lado a lado */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Cuadro izquierda: Biografía */}
          <Biography />

          {/* Cuadro derecha: Habilidades Técnicas */}
          <Skills />
        </div>

        {/* Otras secciones */}
        <section>
          <Timeline />
        </section>

        <section>
          <Certifications />
        </section>
      </div>
    </div>
  );
};

export default About;
