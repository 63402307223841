import React from 'react';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

function Contact() {
  return (
    <div className="bg-[#06071B] text-white py-16 px-8 mt-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
        <ContactInfo />
        <ContactForm />
      </div>
    </div>
  );
}

export default Contact;
