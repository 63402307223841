import React from 'react';
import BinaryRain from '../../components/common/BinaryRain'; // Asegúrate de la ruta correcta

function Hero() {
  return (
    <section
      className="relative h-screen flex flex-col lg:flex-row items-center justify-center bg-[#06071B] overflow-hidden px-4"
    >
      {/* Lluvia Binaria como Fondo en Pantallas Pequeñas y Medianas */}
      <div className="absolute inset-0 z-0 lg:hidden">
        <BinaryRain />
      </div>

      {/* Columna de Texto */}
      <div className="relative lg:w-1/2 w-full text-center lg:text-left z-10 lg:ml-40 md:ml-20 sm:ml-10">
        <h1 className="text-5xl font-bold mb-4 text-white">
          ¡Bienvenido a
        </h1>
        <h1 className="text-5xl font-bold mb-4 text-white">
          Mi Portafolio!
        </h1>

        <p className="text-xl mb-6 text-gray-200">
          Descubre mis proyectos, habilidades y experiencia profesional.
        </p>
        <p className="text-xl mb-8 text-gray-200">
          (Página en construcción)
        </p>
      </div>

      {/* Columna de Lluvia Binaria en Pantallas Grandes */}
      <div className="hidden lg:block lg:w-1/2 w-full h-screen">
        <BinaryRain />
      </div>
    </section>
  );
}

export default Hero;
