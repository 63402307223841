import React from 'react';

// Importación explícita de íconos desde `technologies`
import html5 from '../../assets/images/svg/technologies/frontend/html5.svg';
import css3 from '../../assets/images/svg/technologies/frontend/css3.svg';
import react from '../../assets/images/svg/technologies/frontend/react.svg';
import javascript from '../../assets/images/svg/technologies/programming/javascript.svg';
import typescript from '../../assets/images/svg/technologies/programming/typescript.svg';
import tailwind from '../../assets/images/svg/technologies/frontend/tailwind-css.svg';
import bootstrap from '../../assets/images/svg/technologies/frontend/bootstrap.svg';
import nodejs from '../../assets/images/svg/technologies/backend/node-js.svg';
import springboot from '../../assets/images/svg/technologies/backend/spring-boot.svg';
import mysql from '../../assets/images/svg/technologies/databases/mysql.svg';
import sqlserver from '../../assets/images/svg/technologies/databases/sql-server.svg';
import mongodb from '../../assets/images/svg/technologies/databases/mongodb.svg';
import python from '../../assets/images/svg/technologies/programming/python.svg';
import java from '../../assets/images/svg/technologies/programming/java.svg';
import git from '../../assets/images/svg/technologies/tools/git.svg';
import github from '../../assets/images/svg/social/github.svg';

// Datos organizados por categorías
const skills = {
  'Desarrollo Web': {
    Frontend: [
      { name: 'HTML5', icon: html5 },
      { name: 'CSS3', icon: css3 },
      { name: 'React.js', icon: react },
      { name: 'JavaScript (ES6+)', icon: javascript },
      { name: 'TypeScript', icon: typescript },
      { name: 'Tailwind CSS', icon: tailwind },
      { name: 'Bootstrap', icon: bootstrap },
    ],
    Backend: [
      { name: 'Node.js', icon: nodejs },
      { name: 'Spring Boot', icon: springboot },
    ],
  },
  'Bases de Datos': [
    { name: 'MySQL', icon: mysql },
    { name: 'SQL Server', icon: sqlserver },
    { name: 'MongoDB', icon: mongodb },
  ],
  'Lenguajes de Programación': [
    { name: 'Python', icon: python },
    { name: 'Java', icon: java },
    { name: 'JavaScript', icon: javascript },
  ],
  Herramientas: [
    { name: 'Git', icon: git },
    { name: 'GitHub', icon: github },
  ],
};

const Skills = () => {
  return (
    <section className="bg-[#0E1628] text-white p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-center">Habilidades Técnicas</h2>
      <div className="space-y-8">
        {Object.keys(skills).map((category) => (
          <div key={category}>
            <h3 className="text-2xl font-semibold mb-4">{category}</h3>
            {Array.isArray(skills[category]) ? (
              <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                {skills[category].map((skill, index) => (
                  <div key={index} className="flex flex-col items-center text-center">
                    <img
                      src={skill.icon}
                      alt={`${skill.name} icon`}
                      className="w-12 h-12 mb-2"
                    />
                    <span className="text-sm font-medium text-gray-300">{skill.name}</span>
                  </div>
                ))}
              </div>
            ) : (
              Object.keys(skills[category]).map((subcategory) => (
                <div key={subcategory} className="mb-6">
                  <h4 className="text-lg font-semibold mb-2">{subcategory}</h4>
                  <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                    {skills[category][subcategory].map((skill, index) => (
                      <div key={index} className="flex flex-col items-center text-center">
                        <img
                          src={skill.icon}
                          alt={`${skill.name} icon`}
                          className="w-12 h-12 mb-2"
                        />
                        <span className="text-sm font-medium text-gray-300">{skill.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
