import React from 'react';
import whatsapp from '../../assets/images/svg/social/whatsapp.svg';
import linkedin from '../../assets/images/svg/social/linkedin.svg';
import gmail from '../../assets/images/svg/social/gmail.svg';
import person from '../../assets/images/svg/misc/persona-de-sexo-masculino.svg';

function ContactInfo() {
    return (
        <div className="space-y-6">
            <h2 className="text-4xl font-bold">Ponte en contacto</h2>
            <p className="text-lg text-gray-300">
                Si tienes preguntas, proyectos o simplemente quieres saludar, llena el formulario o utiliza la información de contacto que aparece a continuación.
            </p>
            <div className="space-y-4">
                <div className="flex items-center space-x-3">
                    <img src={person} alt="Persona" className="w-6 h-6" />
                    <span>Kennedy Saul Pino Dextre</span>
                </div>
                <div className="flex items-center space-x-3">
                    <img src={gmail} alt="Gmail" className="w-6 h-6" />
                    <span>systems.kennedy@gmail.com</span>
                </div>
                <div className="flex items-center space-x-3">
                    <img src={whatsapp} alt="WhatsApp" className="w-6 h-6" />
                    <span>+51 902 985 139</span>
                </div>
                <div className="flex items-center space-x-3">
                    <img src={linkedin} alt="LinkedIn" className="w-6 h-6" />
                    <a
                        href="https://www.linkedin.com/in/kennedypinodextre"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-blue-400"
                    >
                        Mi LinkedIn
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;
