import React, { useRef, useEffect } from 'react';

const BinaryRain = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Configuración inicial
    const binary = '01';
    let fontSize = 16; // Tamaño de los caracteres
    let columns;
    let rainDrops;

    const initializeRain = () => {
      canvas.width = canvas.parentElement.offsetWidth;
      canvas.height = canvas.parentElement.offsetHeight;

      fontSize = 16;
      columns = Math.floor(canvas.width / fontSize); // Número de columnas
      rainDrops = Array(columns).fill(null).map(() => ({
        y: Math.random() * canvas.height, // Posición inicial aleatoria
        speed: Math.random() * 3 + 1, // Velocidad aleatoria
        text: Array.from({ length: Math.floor(canvas.height / fontSize) }, () =>
          binary.charAt(Math.floor(Math.random() * binary.length))
        ).join(''), // Generar cadena aleatoria
      }));
    };

    const drawRain = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Limpia el canvas

      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)'; // Fondo semi-transparente para efecto de "trailing"
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#0F0'; // Color verde para los números
      ctx.font = `${fontSize}px monospace`;

      for (let i = 0; i < rainDrops.length; i++) {
        const drop = rainDrops[i];
        const x = i * fontSize;

        // Dibuja cada carácter de la cadena
        for (let j = 0; j < drop.text.length; j++) {
          const char = drop.text[j];
          ctx.fillText(char, x, drop.y + j * fontSize);
        }

        // Mueve la cadena hacia abajo
        drop.y += drop.speed;

        // Reinicia la cadena si sale del canvas
        if (drop.y > canvas.height) {
          drop.y = -fontSize * drop.text.length; // Reinicia fuera de la pantalla
          drop.speed = Math.random() * 3 + 1; // Nueva velocidad aleatoria
          drop.text = Array.from({ length: Math.floor(canvas.height / fontSize) }, () =>
            binary.charAt(Math.floor(Math.random() * binary.length))
          ).join(''); // Nueva cadena aleatoria
        }
      }
    };

    // Animación
    const animate = () => {
      drawRain();
      requestAnimationFrame(animate);
    };

    // Inicialización y redimensionamiento
    initializeRain();
    window.addEventListener('resize', initializeRain);
    animate();

    return () => {
      window.removeEventListener('resize', initializeRain);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="w-full h-full"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
      }}
    ></canvas>
  );
};

export default BinaryRain;
