import React from 'react';
import GrupoFrava from '../../assets/images/backgrounds/proyects/GrupoFrava.webp';
import Ecommerce from '../../assets/images/backgrounds/proyects/ecommerce.webp';

const projects = [
  {
    title: 'Grupo Frava',
    description: 'Implementación de una página web para la empresa Grupo Frava, con el objetivo de mostrar sus servicios , productos y un formulario para contactos.',
    image: GrupoFrava,
    gradient: 'from-purple-700 to-purple-900',
    link: 'https://grupofrava.com'
  },
  {
    title: 'Front Ecommerce',
    description: 'Página FRONT END en construcción, con el objetivo de mostrar productos y servicios de una tienda en línea.',
    image: Ecommerce,
    gradient: 'from-teal-700 to-teal-900',
    link : 'https://e-commerce-navy-psi-33.vercel.app'
  },
  {
    title: 'Proyecto 3',
    description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: require('../../assets/images/backgrounds/home-background.webp'),
    gradient: 'from-indigo-700 to-indigo-900',
    link : ''
  },
  {
    title: 'Proyecto 4',
    description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: require('../../assets/images/backgrounds/home-background.webp'),
    gradient: 'from-blue-700 to-blue-900',
    link : ''
  },
  {
    title: 'Proyecto 5',
    description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: require('../../assets/images/backgrounds/home-background.webp'),
    gradient: 'from-pink-700 to-pink-900',
    link : ''
  },
  {
    title: 'Proyecto 6',
    description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: require('../../assets/images/backgrounds/home-background.webp'),
    gradient: 'from-green-700 to-green-900',
    link : ''
  },
];

function FeaturedProjects() {
  return (
    <section className="py-16 bg-custom-dark">
      {/* Título y descripción */}
      <header className="max-w-screen-xl mx-auto px-6 text-center mb-8">
        <h2 className="text-4xl font-bold text-white">Proyectos Destacados</h2>
        <p className="text-lg text-gray-300">
          Muestra de habilidades y áreas en las que me especializo.
        </p>
      </header>

      {/* Lista de proyectos */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-screen-xl mx-auto px-6">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl bg-gradient-to-b ${project.gradient} transition-transform transform hover:-translate-y-3`}
          >
            <figure>
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-48 object-cover"
              />
            </figure>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-white mb-2">{project.title}</h3>
              <p className="text-gray-200 mb-4">{project.description}</p>
              {project.link ? (
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block px-4 py-2 bg-black/40 text-white rounded-full hover:bg-black/60 transition-colors"
                >
                  Ver Más
                </a>
              ) : (
                <button
                  disabled
                  className="px-4 py-2 bg-gray-500 text-white rounded-full cursor-not-allowed"
                >
                  Sin Enlace
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FeaturedProjects;
